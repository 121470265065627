// App.tsx
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from 'react-router-dom';

// Styles
import './styles/main.css';
import './styles/highlighter-theme.css';
import './styles/preview-stack.css';
import './styles/stack.css';
import './styles/tailwind.css';
import './styles/scrolling.css';

// Components
import Playground from './playground/Playground';
import HomePage from './homepage/HomePage';
import RequestAccess from './access/RequestAccess';
import Header from './Header';
import Docs from './docs/Docs';
import PricingPage from './pricing/PricingPage';
import TermsOfService from './policies/TermsOfService';
import PrivacyPolicy from './policies/PrivacyPolicy';
import PlaygroundTermsAndConditions from './playground/PlaygroundTermsAndConditions';
import ScrollToTop from './ScrollToTop';

// Import your pageView function
import { pageView } from './analytics';

// Create a helper component to log the first page view only once
function LogInitialPageView() {
  const location = useLocation();
  const hasLoggedRef = React.useRef(false);

  useEffect(() => {
    if (!hasLoggedRef.current) {
      pageView(location.pathname);
      hasLoggedRef.current = true;
    }
  }, [location]);

  return null;
}

const App: React.FC = () => {

  return (
    <Router>
      <ScrollToTop />
      <Header />
      <LogInitialPageView />
      <main role="main" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/playground" element={<Playground />} />
          <Route path="/playground/terms-and-conditions" element={<PlaygroundTermsAndConditions />} />
          <Route path="/access" element={<RequestAccess />} />
          <Route path="/docs" element={<Docs />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </main>
    </Router>
  );
};

export default App;
