// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { PostHogProvider } from 'posthog-js/react'

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);

const posthogKey = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;
if (!posthogKey) throw new Error('PostHog API key is required');

root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={posthogKey}
      options={options}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>
);